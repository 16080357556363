@font-face {
  font-family: JustMandrone;
  src: url('../assets/fonts/Just-Mandrawn.ttf');
}

@font-face {
  font-family: PoppinsLight;
  src: url('../assets/fonts/Poppins-Light.ttf');
}

@font-face {
  font-family: PoppinsRegular;
  src: url('../assets/fonts/Poppins-Regular.ttf');
}
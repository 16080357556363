@use './colors';

@keyframes btn_bg_color_change {
  from {
    background-color: colors.$background_orange;
  }

  to {
    background-color: colors.$background_orange_dark;
  }
}

.text--orange {
  color: colors.$text_orange;
}

.bold {
  font-family: PoppinsRegular;
  font-weight: 700;
}

.btn-yellow {
  all: unset;
  padding: 12px;
  background-color: colors.$background_orange;
  border-radius: 8px;
  color: colors.$white;
  text-align: center;
  font-family: PoppinsLight;
  font-weight: 100;
  font-size: 16px;
  line-height: 24px;
  transition: all 100ms;

  cursor: pointer;

  &:hover {
    animation: btn_bg_color_change 100ms ease-in-out;
    background-color: colors.$background_orange_dark;
  }
}

.input-serp {
  all: unset;
  font-family: PoppinsLight;
  font-size: 16px;
  padding: 16px;
  line-height: 18px;
  color: colors.$text_ink_black;
  width: 100%;

  ::placeholder {
    color: colors.$text_grey;
  }
}
@use '../../styles/colors';
@use '../../styles/breakpoints';

.footer {
    padding: 30px 20px;

    .social-media {
        margin-top: 10px;
        margin-bottom: 20px;

        .icon {
            padding: 12px;
            margin-right: 15px;
        }
    }

    .title {
        font-size: 16px;
        line-height: 25px;
        font-weight: 700;
        margin-bottom: 16px;

        &.hide-title {
            display: none;
        }
    }

    nav {
        margin-bottom: 30px;

        li {
            margin-bottom: 16px;
            font-size: 14px;

            a {
                text-decoration: none;
                color: colors.$text_ink_black;
            }
        }

    }

    .all-rights-reserved {
        padding-top: 20px;
        color: colors.$text_grey_darker;
        border-top: 1px solid colors.$border_grey_lighter;
    }
}

@media only screen and (min-width: breakpoints.$hd) {
    .footer {
        padding: 80px 100px 30px 100px;

        .links {
            display: flex;
            justify-content: space-between;

            .logo {
                height: 60px;
            }

            .social-media {
                margin-top: 0;
            }

            .title {
                &.hide-title {
                    display: block;
                }
            }
        }
    }
}
$border_light_grey: #E3E5E6;
$border_grey: #DBDCDC;
$border_grey_lighter: #DFE3E6;

$text_orange: #E08D22;
$text_ink_black: #090A0A;
$text_grey: #979C9E;
$text_grey_darker: #6F777A;

$background_orange: #E08D21;
$background_orange_dark: #BC7110;
$background_grey: #F0F3F6;
$background_dark_blue: #032239;

$white: #FFFFFF;
.scroll-top {
  position: fixed;
  right: 50px;
  bottom: 50px;
  opacity: 0;
  transition: all ease 300ms;
  pointer-events: none;

  &.is-visible {
    pointer-events: all;
    opacity: 1;
    transition: all ease 300ms;
  }

  :hover {
    cursor: pointer;
  }

  &-btn {
    all: unset;
    background-color: white;
    padding: 10px;
    border-radius: 50%;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  }
}
@use '../../../../styles/colors';
@use '../../../../styles/breakpoints';

.total-results {
  padding: 20px;
  background-color: colors.$background_grey;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;

  .title {
    display: none;
  }

  .btn-menu {
    all: unset;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: colors.$white;
    padding: 12px 16px;
    border-radius: 50px;
    font-size: 16px;
    border: 1px solid colors.$border_grey;
    display: none;

    .icon {
      margin-right: 8px;
    }
  }
}

@media only screen and (min-width: breakpoints.$hd) {
  .total-results {
    display: block;
    padding-left: 0;
    padding-top: 26px;

    .title {
      display: block;
      font-size: 26px;
      font-weight: 700;
      color: colors.$background_dark_blue;
      margin-bottom: 10px;
    }

    .bold {
      font-weight: 400;
    }

    .btn-menu {
      display: none;
    }
  }
}